import { Trans } from '@lingui/macro';
import { Box, Typography } from '@mui/material';
import _, { fill } from 'lodash';
import { getHotEvents } from 'src/api/sportbook-v2/event';
import FixtureLoading from 'src/components/core/loading/fixture-loading';
import { useLocale } from 'src/hooks/use-locale';
import { SBLayoutProvider } from 'src/hooks/use-sb-layout';
import { toTournaments } from 'src/utils/helpers/sport';
import { getHotEventsKey } from 'src/utils/helpers/swr';
import useSWR from 'swr';
import { useStyles } from '../../modules/live-sports/styles';
import { FixtureTableV2 } from '../sportsbook-v2/fixture';
import { SeasonItemV2 } from '../sportsbook-v2/season';

const MAX_NUMBER_OF_ITEMS = 5;
type HotEventsProps = {
  title?: React.ReactNode;
};
const HotEvents: React.FC<HotEventsProps> = ({ title }) => {
  const { classes, cx } = useStyles();
  const { data, loading } = useHotEvents();

  const grouped = toTournaments(data ? [data] : []);

  if (!loading && !grouped.length) return null;
  return (
    <Box sx={{ mb: 3 }}>
      <Typography
        sx={{
          fontSize: 18,
          fontWeight: 700,
        }}>
        {title || <Trans id="sportsbook.hot_events">Hot events</Trans>}
      </Typography>

      <div>
        {loading && <FixtureLoading />}
        {!loading &&
          _.take(grouped, MAX_NUMBER_OF_ITEMS).map((season, index) => (
            <SBLayoutProvider
              defaultValues={{
                nation: true,
                seasons: fill(Array(grouped.length), true),
              }}
              key={index}>
              <SeasonItemV2
                index={index}
                className={classes.season}
                data={season}>
                <FixtureTableV2 data={season.__events} index={index} />
              </SeasonItemV2>
            </SBLayoutProvider>
          ))}
      </div>
    </Box>
  );
};

export const useHotEvents = () => {
  const { locale } = useLocale();

  const res = useSWR(
    getHotEventsKey({
      size: MAX_NUMBER_OF_ITEMS,
      lang: locale,
      sort_by: 'startTime',
      order_by: 'ASC',
    }),
    ([, params]) => {
      return getHotEvents(params);
    },
  );

  const { data, isLoading } = res;

  return { loading: isLoading, data };
};

export default HotEvents;
