import {
  Box,
  Link,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import FireIcon from 'src/assets/icons/hot-event.svg?url';
import { MyLink } from 'src/components/core/mine/my-link';
import { useSportFinder } from 'src/hooks/use-current-sport';
import { Paths } from 'src/utils/constants/path';
import { generatePath } from 'src/utils/libs/react-router-dom';
import { BaseEvent } from 'src/utils/types/event';
import { BaseOutcome } from 'src/utils/types/outcome';

interface EventItemProps {
  data: BaseEvent<BaseOutcome>;
  name: string;
}
const EventItem = ({ data, name }: EventItemProps) => {
  const sport = useSportFinder({ sportId: data.sportId });

  const redirect = sport
    ? generatePath(Paths.DetailFixture, {
        sportType: sport.__sportType,
        slug: sport.slug,
        eventId: data._eventSlug ?? data.eventId,
      })
    : '#';

  return (
    <Link
      color="inherit"
      role="button"
      component={MyLink}
      href={redirect}
      key={data._id}
      sx={{
        maxWidth: 'max-content',
        minWidth: 'fit-content',
        bgcolor: 'custom.bg.3',
        borderRadius: '4px',
        p: 1,
        display: 'flex',
        gap: 1,
      }}>
      <Box
        component="img"
        src={FireIcon.src}
        sx={{
          width: 18,
          height: 18,
        }}
      />
      <span>{name}</span>
    </Link>
  );
};

export default EventItem;
