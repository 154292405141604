import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import {
  Autocomplete,
  Backdrop,
  InputAdornment,
  InputBaseProps,
  Paper,
  PaperProps,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import SearchIcon from 'src/assets/icons/search.svg';
import { UnstyledMyInput } from 'src/components/core/mine/my-input';
import { MyLink } from 'src/components/core/mine/my-link';
import { CasinoThumbnail } from 'src/components/modules/casino/casino-thumbnail';
import { QueryMapping } from 'src/hooks/use-breakpoint';
import { useSwrCasinoGames } from 'src/hooks/use-casino-games';
import { useMyRouter } from 'src/hooks/use-locale';
import { useQuery } from 'src/hooks/use-query';
import { DOWN_500 } from 'src/theme';
import { Paths } from 'src/utils/constants/path';
import { generatePath } from 'src/utils/libs/react-router-dom';
import { CasinoGame } from 'src/utils/types/casino';
import { makeStyles } from 'tss-react/mui';

type LoadableCasinoGame = CasinoGame & { _more: boolean };

interface SearchInputProps extends InputBaseProps {}

const mobile = QueryMapping.mobile;
const useStyles = makeStyles()((theme) => ({
  autocomplete: {
    margin: '0px 16px',
    [mobile]: {
      margin: 'none',
    },
  },
  option: {
    padding: 12,
    '& > a': {
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      color: 'inherit',
    },
    '&:active, &[data-focus="true"]': {
      backgroundColor: theme.custom.bg[2],
    },
  },
  search: {
    borderRadius: '3px',
    border: `1px solid ${theme.palette.divider}`,
    height: 38,
    flexBasis: 280,
    '& > input': {
      padding: `10px 16px 10px 0px`,
    },
  },
  inputSearch: {
    padding: '10px 16px 10px 0px',
    '&::placeholder': {
      color: theme.custom.content[2],
      opacity: 1,
    },
  },
  paper: {
    backgroundColor: theme.palette.background.default,
    width: 400,
    [DOWN_500]: {
      width: '100%',
    },
  },
  itemLeft: {
    width: 59.4,
    marginRight: 16,
  },
  itemRight: {
    '& > *': {
      fontSize: 16,
      lineHeight: 5 / 4,

      '&.title': {
        fontWeight: 'bold',
        marginBottom: 8,
      },
    },
  },
}));

export const SearchInput: React.FCC<
  SearchInputProps & { onSearch?: () => void }
> = (props) => {
  const { classes: classesProps, onSearch, sx } = props;
  const { classes, cx } = useStyles();
  const [search, setSearch] = useState('');
  const router = useMyRouter();
  const queries = useQuery('q');
  const [open, setOpen] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    _.debounce((txt: string) => setSearch(_.trim(txt)), 300),
    [],
  );
  const { i18n } = useLingui();
  const { isLoading, data } = useSwrCasinoGames({
    page_size: 3,
    title_like: search,
  });

  const options = _.first(data)?.items ?? [];

  const isEmpty = _.isEmpty(options);

  return (
    <>
      <Autocomplete
        sx={sx}
        openOnFocus
        onFocus={(e) => {
          setOpen(true);
        }}
        onBlur={(e) => {
          setOpen(false);
        }}
        options={options as LoadableCasinoGame[]}
        // this func handles keyboard behaviors (arrow up, down)
        onChange={(e, value) => {
          if (!value || isLoading || isEmpty) return;

          if (typeof value === 'string') {
            const q = _.trim(value);
            q &&
              router.push(Paths.Search, {
                query: { q },
              });
          } else if (value._more) {
            router.push(Paths.Search, {
              query: { q: _.trim(value.title) },
            });
          } else if (value) {
            const pathname = generatePath(Paths.MetaCasino, {
              category: value.categoryConfig,
              gameId: value.identifier,
            });

            router.push(pathname);
          }
        }}
        freeSolo
        defaultValue={(queries.q?.toString() ?? undefined) as any}
        loading={isEmpty || isLoading}
        loadingText={
          <Typography color="custom.content.2">
            {isLoading ? (
              <Trans id="common.loading">Loading...</Trans>
            ) : isEmpty ? (
              <Trans id="empty.no_result">No results found</Trans>
            ) : (
              <Trans id="common.loading">Loading...</Trans>
            )}
          </Typography>
        }
        onInputChange={(e, val) => {
          handleSearch(val);
        }}
        PaperComponent={SearchPaper}
        classes={{
          root: classes.autocomplete,
          option: classes.option,
        }}
        getOptionLabel={(opts) => {
          return typeof opts === 'string'
            ? opts
            : opts.title ?? opts.identifier;
        }}
        renderOption={(props, opts) => {
          const title = opts.title;
          if (opts._more && title) {
            return (
              // @ts-ignore
              <MyLink
                underline="none"
                href={{
                  pathname: Paths.Search,
                  query: { q: _.trim(title) },
                }}
                {...props}
                color="primary"
                onClick={onSearch}>
                <Trans id="casino.search.more_result">
                  See all results for ‘{title}’...
                </Trans>
              </MyLink>
            );
          }

          return <SearchOption {...props} data={opts} onSelect={onSearch} />;
        }}
        filterOptions={(options, params) => {
          const title = _.trim(params.inputValue);
          if (title && options.length > 0) {
            return [...options, { _more: true, title } as LoadableCasinoGame];
          }

          return options;
        }}
        renderInput={(params) => {
          return (
            <div ref={params.InputProps.ref}>
              <UnstyledMyInput
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
                inputProps={params.inputProps}
                fullWidth
                type="search"
                placeholder={t(i18n)({
                  message: `Search 1000’s of casino games`,
                  id: 'casino.search.placeholder',
                })}
                classes={{
                  root: cx(classes.search, classesProps?.root),
                  input: cx(classes.inputSearch, classesProps?.input),
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </div>
          );
        }}
      />
      <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: 'none',
          [QueryMapping.mobile]: {
            display: 'block',
          },
        }}
        open={open}
      />
    </>
  );
};

const SearchPaper: React.FCC<PaperProps> = (props) => {
  const { classes, cx } = useStyles();

  return (
    <Paper
      {...props}
      className={cx(props.className, classes.paper)}
      variant="outlined"
    />
  );
};

const SearchOption: React.FCC<{
  data: LoadableCasinoGame;
  onSelect?: () => void;
}> = ({ data, onSelect, ...props }) => {
  const { classes, cx } = useStyles();

  const handleSelect = () => onSelect && onSelect();

  return (
    <MyLink
      {...props}
      underline="none"
      color={'inherit'}
      href={generatePath(Paths.MetaCasino, {
        category: data.categoryConfig,
        gameId: data.identifier,
      })}
      onClick={handleSelect}>
      <div className={classes.itemLeft}>
        <CasinoThumbnail disableHover data={data} FallbackProps={{}} />
      </div>

      <div className={classes.itemRight}>
        <Typography className="title">{data.title}</Typography>
        <Typography color="custom.content.2">{data.producerName}</Typography>
      </div>
    </MyLink>
  );
};
