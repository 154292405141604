import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Box, Link as MuiLink, Typography } from '@mui/material';
import { useSetAtom } from 'jotai';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { getCampaigns } from 'src/api/campaign';
import FireIcon from 'src/assets/icons/hot-bet.svg?url';
import {
  ErrorTypes,
  parseBsError,
} from 'src/components/modules/bet-slip-drawer/components/shared/error-message';
import { QueryMapping } from 'src/hooks/use-breakpoint';
import { addCampaignAtom } from 'src/store/bet-slip';
import { CampaignStatus } from 'src/utils/helpers/campaign';
import { getCampaignsKey } from 'src/utils/helpers/swr';
import useSWR from 'swr';

interface HotBetsProps {
  onClick?: () => void;
}

export const HotBets: React.FC<HotBetsProps> = ({ onClick }) => {
  const { data, isLoading } = useSWR(
    getCampaignsKey({
      page: 1,
      size: -1,
      status: CampaignStatus.ACTIVE,
      sortBy: 'endDateTime',
      orderBy: 'ASC',
    }),
    ([, params]) => getCampaigns(params),
  );
  const { i18n } = useLingui();
  const addToCart = useSetAtom(addCampaignAtom);
  const { enqueueSnackbar } = useSnackbar();

  if (isLoading || _.isEmpty(data?.items)) {
    return null;
  }

  return (
    <Box sx={{ mb: 3 }}>
      <Typography
        gutterBottom
        sx={{
          fontSize: 18,
          fontWeight: 700,
        }}>
        <Trans id="home.hot_bets">Hot bets</Trans>
      </Typography>

      <Box
        sx={{
          display: 'flex',
          gap: '10px',
          flexWrap: 'wrap',
          [QueryMapping.mobile]: {
            flexWrap: 'nowrap',
            overflowY: 'hidden',
            scrollSnapType: 'x mandatory',
            scrollbarWidth: 'none',
            overflowX: 'scroll',
          },
        }}>
        {data?.items.flatMap((event) => {
          return event.markets.flatMap((market) => {
            return market.outcomes.map((x) => {
              return (
                <MuiLink
                  onClick={async () => {
                    try {
                      await onClick?.();
                      await addToCart({ event, outcomeId: x._id });
                    } catch (err: any) {
                      if (err.self_code === ErrorTypes.SelfExclude) {
                        enqueueSnackbar(
                          parseBsError({
                            code: ErrorTypes.SelfExclude,
                            i18n,
                          }),
                          { variant: 'error', className: 'ubet-err' },
                        );
                      }
                    }
                  }}
                  color="inherit"
                  role="button"
                  component="div"
                  key={x._id}
                  sx={{
                    maxWidth: 'max-content',
                    minWidth: 'fit-content',
                    bgcolor: 'custom.bg.3',
                    borderRadius: '4px',
                    p: 1,
                    display: 'flex',
                    gap: 1,
                  }}>
                  <Box
                    component="img"
                    src={FireIcon.src}
                    sx={{
                      width: 18,
                      height: 18,
                    }}
                  />
                  <span>{x.name}</span>
                </MuiLink>
              );
            });
          });
        })}
      </Box>
    </Box>
  );
};
